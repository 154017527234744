





































































































  @import '~@/styles/import';

.summary {
  .description {
    p:not([class^="pb-"]):not([class*=" pb-"]) {
      @apply pb-16;
    }
  }

  .actions {
    @apply grid pt-16 pb-16 mb-24 border-t-1 border-b-1 border-gray;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-auto-flow: row;
    grid-row-gap: 3.6rem;

    @media (min-width: 1024px) {
      grid-auto-flow: column;
    }
    .action {
      @apply block text-center font-semibold grid text-gray cursor-pointer justify-items-center;
      grid-template-columns: 1fr;
      grid-auto-rows: 4rem 1fr;
      grid-row-gap: 1rem;
      &:hover {
        @apply text-dark;
      }
    }
  }
}
